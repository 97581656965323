import { render, staticRenderFns } from "./verifications.vue?vue&type=template&id=bca4826a&scoped=true&"
import script from "./verifications.vue?vue&type=script&lang=js&"
export * from "./verifications.vue?vue&type=script&lang=js&"
import style0 from "./verifications.vue?vue&type=style&index=0&id=bca4826a&ref=less&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca4826a",
  null
  
)

export default component.exports